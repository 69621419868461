import React, { Component } from 'react';
import '../carousel.css';
import d1 from '../assets/1.jpg';
import d2 from '../assets/2.jpg';
import d3 from '../assets/3.jpg';
import d4 from '../assets/4.jpg';
import d5 from '../assets/5.jpg';
import d6 from '../assets/6.jpg';
import d7 from '../assets/7.jpg';
import d8 from '../assets/11.jpg';
import d9 from '../assets/9.jpg';
import d10 from '../assets/10.jpg';

class Carousel extends Component {

    render() {
        return (

            <div class="boxWrapC">

                <div class="cT" data-aos="fade-up" data-aos-duration="700">Explore I AM SAM NFTs</div>
                <p class="cT2" data-aos="fade-up" data-aos-duration="700"><span class="in3">I AM SAM NFTs were made to bring fun back to the community.</span></p>
                <p class="cT2" data-aos="fade-up" data-aos-duration="700"><span class="in3M">I AM SAM NFTs were made to bring fun back to the community.</span></p>

                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d1} alt="d5" />
                                <div class="number">#1</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d2} alt="d5" />
                                <div class="number">#2</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d3} alt="d5" />
                                <div class="number">#3</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d4} alt="d5" />
                                <div class="number">#4</div>
                            </div>
                        </div>

                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d5} alt="d5" />
                                <div class="number">#5</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d6} alt="d5" />
                                <div class="number">#6</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d7} alt="d5" />
                                <div class="number">#7</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d8} alt="d5" />
                                <div class="number">#8</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d9} alt="d5" />
                                <div class="number">#9</div>
                            </div>
                        </div>
                        <div class="slide">
                            <div class="imgBg">
                                <img class="carouselIMG" src={d10} alt="d5" />
                                <div class="number">#10</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Carousel;        