import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import img1 from '../assets/bc.png';
import img3 from '../assets/stellio.png';
import banner2 from '../assets/Group 36565.png';

const tg = () => {
    window.open("https://t.me/iamsamnft");
}

const img1L = () => {
    window.open("https://blockchatdao.com/");
}

const img3L = () => {
    window.open("https://stellio.com.au");
}

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});
function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 15;

        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        } else {
            reveals[i].classList.remove("active");
        }
    }
}

window.addEventListener("scroll", reveal);

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});


class Team extends Component {

    async componentDidMount() {

    }

    render() {
        return (
            <div class="boxWrap2Rm">
					<img class="banner2"  onClick={tg} src={banner2} />

					<div class="tgM">
						<div>JOIN OUR <br/> COMMUNITY</div>
						<button onClick={tg}>JOIN TELEGRAM</button>
					</div>
                    
                <div class="conT2" data-aos="fade-up" data-aos-duration="700">OUR PARTNERS</div>

                <div class="partnerMain1">
                    <div class="partnerMain">
                        <img onClick={img1L} src={img1} />
                        <p>BlockChat- Your Gateway to the Future of Decentralised Community Building</p>
                    </div>

                    <div class="partnerMain">
                        <img onClick={img3L} src={img3} />
                        <p>Simplify Your Design Process with an All-Inclusive Graphic Design Monthly Subscription. Pause or Cancel Anytime.</p>
                      </div>
                </div>
            </div>
        )
    }
}

export default Team;