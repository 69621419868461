import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import msg from '../assets/msg.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="about" data-aos="fade-up" data-aos-duration="700">
                    <img src={msg} />

                    <div class="storyConMain">
                    <div class="storyCon">
                        <p>"What if I told you that we are the same, you and I? What if beneath my alien features and your human skin, you discovered our hearts and souls resonate with the same vibration? Would it change the way you look at me?  Would it impact your relationship with others, with your planet and all living beings on it? What if I told you that your experience in this body and this life is based on your choices, and that you can begin to heal by changing your focus? It starts with an awareness of loving kindness to all living entities and your world around you."</p>
                    </div>
                    </div>

                </div>

                <div class="mainDiv">
                    <div class="divM">
                        <div class="div1">TOTAL</div>
                        <div class="div2">5000</div>
                    </div>
                    
                    <div class="whiteLine"></div>

                    <div class="divM">
                        <div class="div1">CHAIN</div>
                        <div class="div2">ANDROMEDA</div>
                    </div>

                </div>

            </div>
        )
    }
}

export default Story;

